import * as React from 'react'
import reset from 'styled-reset'
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components'
import Seo from '../components/parts/Seo'
import Logo from '../static/images/logo.svg'
import theme from '../config/theme'
import Heading from '../components/parts/Heading'
import Paragraph from '../components/parts/Paragraph'
import HardFacts from '../components/parts/HardFacts'

const GlobalStyle = createGlobalStyle`
  ${reset}
`

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding: 6rem 1rem 0 1rem;
  text-align: center;
  background-color: ${theme.palette.sand[1]};
`

const FancyText = styled.p`
  margin: 0;
  margin-top: 6rem;
  color: ${theme.palette.sand[0]};
  font-size: 7.5rem;
  font-family: ${theme.fonts.libreBaskerville};
  transform: rotate(-6deg) translateY(1rem);
  ${theme.media.mdDown} {
    font-size: 6rem;
  }
  ${theme.media.smDown} {
    font-size: 4rem;
  }
`

const HeadingStyled = styled(Heading)`
  z-index: ${theme.zIndex.base};
  max-width: 29rem;
  margin-bottom: 6.5rem;
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
  ${theme.media.smDown} {
    margin-bottom: 4rem;
    font-size: 1.5rem;
  }
`

const ParagraphStyled = styled(Paragraph)`
  max-width: 32rem;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: center;
`

const LinkStyled = styled.a`
  color: ${theme.palette.black[0]};
  font-family: ${theme.fonts.munkenSans};
`

const Success = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Seo title="Demnächst" />
    <HardFacts />
    <ContainerStyled>
      <Logo />
      <FancyText>Coming Soon!</FancyText>
      <HeadingStyled level={1}>Wir machen gerade alles neu und noch schöner!</HeadingStyled>
      <ParagraphStyled>
        Wir sind spätestens am 16.03.2021 wieder für dich da. Solltest du bis dahin Fragen haben,
        erreichst du uns immer unter
      </ParagraphStyled>
      <LinkStyled href="mailto:abo@strollme.de">abo@strollme.de.</LinkStyled>
    </ContainerStyled>
  </ThemeProvider>
)

export default Success
